import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header'; // Import your Header component
import Footer from './Footer'; // Import your Footer component

const Layout = () => {
  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
