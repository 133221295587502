import React from 'react';

// Sample logos
import logo1 from '../assets/images/brand1.png';
import logo2 from '../assets/images/brand2.png';
import logo3 from '../assets/images/brand3.png';
import logo4 from '../assets/images/brand4.png';
import logo5 from '../assets/images/brand5.png';
import logo7 from '../assets/images/brand7.png';

const Partners = () => {
  const logos = [logo1, logo2, logo3, logo4, logo5, logo7];

  return (
    <section className="partners py-40   bg-blue-700">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-400">They chose Wells ideas because visibility matters</h2>
        <div className="flex flex-wrap justify-center gap-4 p-10">
          {logos.map((logo, index) => (
            <div key={index} className="flex-shrink-0 p-4">
              <img 
                src={logo} 
                alt={`Partner ${index + 1}`} 
                className="h-6 sm:h-8 object-contain transition-transform duration-300 hover:scale-110" 
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;
