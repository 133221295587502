import React from 'react';
import agencyVideo from '../assets/videos/agency.mp4';

const WhoWeAre = () => {
  return (
    <section className="whoweare text-white py-12 px-4 md:px-20 flex flex-col md:flex-row items-center justify-center relative">
      {/* Video Container */}
      <div className="w-full md:w-1/2 mb-8 md:mb-0 md:mr-8">
        <div className="max-w-full rounded-md overflow-hidden">
          {/* Video Element */}
          <video
            className="w-full h-auto rounded-md"
            src={agencyVideo}
            controls
            autoPlay
            loop
            muted
          />
        </div>
      </div>

      {/* Text Content */}
      <div className="whoweare-content z-10 relative text-center md:text-left md:w-1/2">
        <h2 className="text-3xl md:text-4xl font-bold leading-tight mb-4 md:mb-8 text-gray-600">
          We Create <span className="font-black text-gray-600">Impactful</span> Experiences
        </h2>
        <p className="text-base md:text-lg text-gray-500 max-w-full mx-auto md:max-w-2xl">
          We are a digital and physical touchpoint agency that designs experiences based on what customers need and want, in combination with the brand's promise. We don't just deliver cookie-cutter solutions – we go beyond the ordinary to create experiences that are more than meets the eye.
        </p>
        <button className="bg-blue text-gray-900 rounded-full px-4 md:px-6 py-2 border border-gray-400  hover:text-blue-100 hover:bg-blue-600 transition duration-300 mt-6 md:mt-8 focus:outline-none">
          Learn More
        </button>
      </div>
    </section>
  );
};

export default WhoWeAre;
