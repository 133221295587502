import React from 'react';
import './Services.css'; // Import the custom CSS

const services = [
  {
    title: 'UI/UX Design',
    description: 'Product that has a good appearance can attract users',
    iconClass: 'icon-class-for-uiux', // replace with actual icon class if needed
  },
  {
    title: 'Logo Branding',
    description: 'Product identity is needed to have a characteristic',
    iconClass: 'icon-class-for-logo-branding', // replace with actual icon class if needed
  },
  {
    title: '3D Design',
    description: '3D design is something new and has a high chance',
    iconClass: 'icon-class-for-3d', // replace with actual icon class if needed
  },
  {
    title: 'Illustration',
    description: 'Make your product more eye-catching with a touch of illustration',
    iconClass: 'icon-class-for-illustration', // replace with actual icon class if needed
  },
  {
    title: 'Web/App Dev.',
    description: 'In this era, Web and App are the backbone of the digital experience',
    iconClass: 'icon-class-for-digital-painting', // replace with actual icon class if needed
  },
  {
    title: 'Print Design',
    description: 'It is undeniable that businesses must still have print designs',
    iconClass: 'icon-class-for-print', // replace with actual icon class if needed
  },
];

const Service = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 font-atyp">
        {/* Intro Section */}
        <section className="text-center mb-10 font-atyp bg-slate-200 py-28 flex flex-col items-center rounded-xl">
          <h1 className="table-header-text text-4xl font-bold mb-4 font-atyp">What We Bring to the Table</h1>
          <p className="text-gray-600 font-atyp w-full sm:w-3/4 lg:w-2/3">
            We offer a comprehensive suite of creative and digital services designed to elevate your brand. From crafting intuitive UI/UX designs that enhance user experiences to developing standout logos that define your brand’s identity, our team is dedicated to bringing your vision to life.
          </p>
        </section>

        {/* Service Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className={`p-6 rounded-2xl border transform transition-transform duration-300 hover:scale-105 ${index === 1 ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-white hover:bg-gray-200'}`}
            >
              <div className={`mb-4 ${service.iconClass}`}></div>
              <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
              <p className="mb-4">{service.description}</p>
              <a href="#" className={`text-${index === 1 ? 'white' : 'blue-600'} hover:text-${index === 1 ? 'gray-300' : 'blue-800'}`}>
                Learn more →
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Service;
