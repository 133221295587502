import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import 'remixicon/fonts/remixicon.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header className="text-gray-500 font-atyp">
        <div className="container mx-auto flex items-center justify-between py-4 px-4">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="Logo" className="h-12" />
            </Link>
          </div>
          {/* Mobile Menu Toggle Button */}
          <div className="md:hidden flex items-center">
            <button onClick={toggleMenu} className="text-gray focus:outline-none">
              <i className="ri-menu-line text-3xl"></i>
            </button>
          </div>
          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-20">
            <ul className="flex space-x-6">
              <li>
                <Link to="/about" className="header-link hover:text-gray-300" onClick={() => setIsOpen(false)}>About Us</Link>
              </li>
              <li>
                <Link to="/services" className="header-link hover:text-gray-300" onClick={() => setIsOpen(false)}>Services</Link>
              </li>
              <li>
                <Link to="/projects" className="header-link hover:text-gray-300" onClick={() => setIsOpen(false)}>Projects</Link>
              </li>
              <li>
                <Link to="/developments" className="header-link hover:text-gray-300" onClick={() => setIsOpen(false)}>Developments</Link>
              </li>
              <li>
                <Link to="/contact" className="header-link hover:text-gray-300" onClick={() => setIsOpen(false)}>Contact</Link>
              </li>
            </ul>
            <div className="flex space-x-4 ml-6">
              <a href="https://www.instagram.com/wellsideas" target="_blank" rel="noopener noreferrer">
                <i className="ri-instagram-line text-xl hover:text-blue-500"></i>
              </a>
              <a href="https://www.twitter.com/wells_ideas" target="_blank" rel="noopener noreferrer">
                <i className="ri-twitter-line text-xl hover:text-blue-500"></i>
              </a>
              <a href="https://www.tiktok.com/wellsideas" target="_blank" rel="noopener noreferrer">
                <i className="ri-tiktok-line text-xl hover:text-blue-500"></i>
              </a>
            </div>
          </nav>
        </div>
        {/* Mobile Navigation (conditionally rendered) */}
        {isOpen && (
          <nav className="md:hidden bg-blue px-6 py-4">
            <ul className="flex flex-col space-y-4">
              <li>
                <Link to="/about" className="header-link hover:text-gray-300" onClick={toggleMenu}>About Us</Link>
              </li>
              <li>
                <Link to="/services" className="header-link hover:text-gray-300" onClick={toggleMenu}>Services</Link>
              </li>
              <li>
                <Link to="/projects" className="header-link hover:text-gray-300" onClick={toggleMenu}>Projects</Link>
              </li>
              <li>
                <Link to="/developments" className="header-link hover:text-gray-300" onClick={toggleMenu}>Developments</Link>
              </li>
              <li>
                <Link to="/contact" className="header-link hover:text-gray-300" onClick={toggleMenu}>Contact</Link>
              </li>
            </ul>
            <div className="flex space-x-4 mt-4">
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <i className="ri-instagram-line text-xl hover:text-blue-500"></i>
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                <i className="ri-twitter-line text-xl hover:text-blue-500"></i>
              </a>
              <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
                <i className="ri-tiktok-line text-xl hover:text-blue-500"></i>
              </a>
            </div>
          </nav>
        )}
      </header>
      <style jsx>{`
        .header-link {
          position: relative;
        }
        .header-link::after {
          content: '';
          position: absolute;
          width: 0%;
          height: 2px;
          bottom: -2px;
          left: 0;
          background-color: blue;
          transition: width 0.3s ease-in-out;
        }
        .header-link:hover::after {
          width: 100%;
        }
      `}</style>
    </>
  );
};

export default Header;
