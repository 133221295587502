import React, { useState, useEffect } from 'react';
import { RiChat1Line } from 'react-icons/ri';
import './Slider.css'; 

const Slider = () => {
  const messages = [
    "not just a cookie in the jar.",
    "not your cup of joe.",
    "not a drop in the ocean.",
    "more than meets the eye.",
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    const message = messages[currentMessageIndex];
    let charIndex = 0;

    const typewriterInterval = setInterval(() => {
      setDisplayedText((prevText) => prevText + message[charIndex]);
      charIndex += 1;
      if (charIndex === message.length) {
        clearInterval(typewriterInterval);
        setTimeout(() => {
          setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
          setDisplayedText("");
        }, 3000); // Delay before switching to the next message
      }
    }, 100); // Speed of the typewriter effect

    return () => {
      clearInterval(typewriterInterval);
    };
  }, [currentMessageIndex]);

  return (
    <div className="slider-container text-white py-10 px-4 rounded-md text-left flex flex-col items-center justify-center md:flex-row md:items-start md:justify-start">
      {/* Background Gradient */}
      <div className="slider-background"></div>

      {/* Slider Content */}
      <div className="slider-content font-bold tracking-tight overflow-hidden text-center md:text-left w-full">
        We're <span className='tracking-tighter'>{displayedText}</span>
      </div>

      {/* Let's Chat Button */}
      <a 
        href="https://wa.me/2347060878315" 
        target="_blank" 
        rel="noopener noreferrer"
        className="chat-button"
      >
        <RiChat1Line style={{ fontSize: '30px', color: '#333' }} />
        <p style={{ fontSize: '14px', color: '#333', fontWeight: 'bold', margin: 0 }}>Let's Chat</p>
      </a>
    </div>
  );
};

export default Slider;
