import React from 'react';
import bgImage from '../assets/images/bg-2.png';
import teamMember1 from '../assets/images/tobi1.jpeg';
import teamMember2 from '../assets/images/member5.jpg';
import teamMember3 from '../assets/images/member3.jpg';
import teamMember4 from '../assets/images/Dami.jpg';
import teamMember5 from '../assets/images/folake.jpg';
import teamMember6 from '../assets/images/member6.jpg';
import teamMember7 from '../assets/images/member7.jpeg';
import teamMember8 from '../assets/images/member8.jpg';

import './About.css';

const About = () => {
  return (
    <div>
      {/* About Us Section with Blue Overlay */}
      <section
        className="hd-bg text-center mb-10 font-atyp py-36 flex flex-col items-center rounded-xl px-3 lg:px-6 bg-cover bg-center relative"
        style={{
          backgroundImage: `url(${bgImage})`
        }}
      >
        {/* Blue Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-blue-700 opacity-75 z-0"></div>

        {/* Content in Front */}
        <div className="abt-head relative z-10">
          <h1 className="abt-title text-4xl font-bold mb-4 font-atyp animate-slide-fade text-white">
            We are not just a Cookie in the big jar!
          </h1>
          <p className="abt-para-text max-w-4xl animate-slide-fade text-white line-height: 1.5; ">
            We believe that innovation is the cornerstone of progress. Founded with a passion for creativity and a commitment to excellence, our agency is dedicated to transforming bold concepts into tangible realities. Whether it's branding, digital development, or strategic design, we bring ideas to life with precision and flair.

            Our team is a diverse collective of thinkers, creators, and strategists who thrive on collaboration and problem-solving. We pride ourselves on our ability to listen, understand, and respond to our clients' needs with tailored solutions that drive success. From mobile apps to comprehensive digital campaigns, we approach each project with a fresh perspective, ensuring that every detail aligns with our clients' vision.

            At Wells Ideas, we're not just about delivering services; we're about building lasting partnerships. We take the time to understand your business, your goals, and your audience, crafting strategies that resonate and engage. Our goal is to empower you with the tools and insights you need to stand out in a crowded marketplace.
          </p>
        </div>

        {/* Animation for Text */}
        <style jsx>{`
          @keyframes slideFadeIn {
            0% {
              opacity: 0;
              transform: translateY(20px);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }

          .animate-slide-fade {
            animation: slideFadeIn 1s ease-out forwards;
          }
        `}</style>
      </section>

      {/* Vision and Mission Section */}
      <section className="bg-white py-16 px-6 lg:px-10">
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-10 max-w-5xl mx-auto">
          {/* Vision Section */}
          <div className="text-center lg:text-left flex flex-col items-center lg:items-start max-w-md">
            <i className="ri-lightbulb-flash-line text-yellow-500 text-5xl mb-4"></i>
            <h2 className="text-2xl font-bold font-atyp">Vision</h2>
            <p className="text-gray-700 font-atyp">
              To be a leading force in the creative industry, where innovative ideas shape the future and empower brands to achieve their fullest potential. We envision a world where creativity knows no bounds, and every idea has the power to transform industries and inspire change.
            </p>
          </div>

          {/* Mission Section */}
          <div className="text-center lg:text-left flex flex-col items-center lg:items-start max-w-md">
            <i className="ri-focus-2-line text-red-500 text-5xl mb-4"></i>
            <h2 className="text-2xl font-bold font-atyp">Mission</h2>
            <p className="text-gray-700 font-atyp">
              We are committed to delivering excellence through collaboration, innovation, and a deep understanding of our clients' needs. By combining creative thinking with strategic execution, we aim to turn our clients' visions into realities that drive growth and success.
            </p>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <div className="team-container bg-gray-200 py-16 px-6 lg:px-10">
        <h2 className="abt-core text-4xl font-bold text-center mb-10 font-atyp">Meet Our Core Team</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 max-w-6xl mx-auto">

          {/* Team Member 1 */}
          <div className="relative group rounded-none overflow-hidden text-center h-72 w-72 flex-1">
            <img src={teamMember1} className="w-full h-full object-cover transition-transform duration-300 transform group-hover:scale-110" />
            <div className="absolute inset-0 bg-yellow-500 opacity-50 group-hover:opacity-80 transition-opacity duration-300"></div>
            <div className="absolute inset-0 flex flex-col justify-end items-center z-10 p-4">
              <p className="text-lg font-semibold font-atyp text-white">Oluwatobi Ogidiolu</p>
              <p className="text-gray-200 font-atyp">CEO & Founder</p>
            </div>
          </div>

          {/* Team Member 2 */}
          <div className="relative group rounded-none overflow-hidden text-center h-72 w-72 flex-1">
            <img src={teamMember2} className="w-full h-full object-cover transition-transform duration-300 transform group-hover:scale-110" />
            <div className="absolute inset-0 bg-red-500 opacity-50 group-hover:opacity-80 transition-opacity duration-300"></div>
            <div className="absolute inset-0 flex flex-col justify-end items-center z-10 p-4">
              <p className="text-lg font-semibold font-atyp text-white">Desire Olarotimi</p>
              <p className="text-gray-200 font-atyp">Lead Developer</p>
            </div>
          </div>

          {/* Team Member 3 */}
          <div className="relative group rounded-none overflow-hidden text-center h-72 w-72 flex-1">
            <img src={teamMember3} className="w-full h-full object-cover transition-transform duration-300 transform group-hover:scale-110" />
            <div className="absolute inset-0 bg-blue-500 opacity-50 group-hover:opacity-80 transition-opacity duration-300"></div>
            <div className="absolute inset-0 flex flex-col justify-end items-center z-10 p-4">
              <p className="text-lg font-semibold font-atyp text-white">Adaobi Umeh</p>
              <p className="text-gray-200 font-atyp">Project Manager</p>
            </div>
          </div>

          {/* Team Member 4 */}
          <div className="relative group rounded-none overflow-hidden text-center h-72 w-72 flex-1">
            <img src={teamMember4} className="w-full h-full object-cover transition-transform duration-300 transform group-hover:scale-110" />
            <div className="absolute inset-0 bg-green-500 opacity-50 group-hover:opacity-80 transition-opacity duration-300"></div>
            <div className="absolute inset-0 flex flex-col justify-end items-center z-10 p-4">
              <p className="text-lg font-semibold font-atyp text-white">Damilare Ojo</p>
              <p className="text-gray-200 font-atyp">UI/UX Designer</p>
            </div>
          </div>

          {/* Team Member 5 */}
          <div className="relative group rounded-none overflow-hidden text-center h-72 w-72 flex-1">
            <img src={teamMember5} className="w-full h-full object-cover transition-transform duration-300 transform group-hover:scale-110" />
            <div className="absolute inset-0 bg-purple-500 opacity-50 group-hover:opacity-80 transition-opacity duration-300"></div>
            <div className="absolute inset-0 flex flex-col justify-end items-center z-10 p-4">
              <p className="text-lg font-semibold font-atyp text-white">Adefolakemi Ojo</p>
              <p className="text-gray-200 font-atyp">HR/Accountant</p>
            </div>
          </div>

          {/* Team Member 6 */}
          <div className="relative group rounded-none overflow-hidden text-center h-72 w-72 flex-1">
            <img src={teamMember6} className="w-full h-full object-cover transition-transform duration-300 transform group-hover:scale-110" />
            <div className="absolute inset-0 bg-orange-500 opacity-50 group-hover:opacity-80 transition-opacity duration-300"></div>
            <div className="absolute inset-0 flex flex-col justify-end items-center z-10 p-4">
              <p className="text-lg font-semibold font-atyp text-white">Casmire Okedinachi</p>
              <p className="text-gray-200 font-atyp">Business Consultants</p>
            </div>
          </div>

          {/* Team Member 7 */}
          <div className="relative group rounded-none overflow-hidden text-center h-72 w-72 flex-1">
            <img src={teamMember7} className="w-full h-full object-cover transition-transform duration-300 transform group-hover:scale-110" />
            <div className="absolute inset-0 bg-teal-500 opacity-50 group-hover:opacity-80 transition-opacity duration-300"></div>
            <div className="absolute inset-0 flex flex-col justify-end items-center z-10 p-4">
              <p className="text-lg font-semibold font-atyp text-white">Philip Nwakam</p>
              <p className="text-gray-200 font-atyp">Machine Learning/Frontend</p>
            </div>
          </div>

          {/* Team Member 8 */}
          <div className="relative group rounded-none overflow-hidden text-center h-72 w-72 flex-1">
            <img src={teamMember8} className="w-full h-full object-cover transition-transform duration-300 transform group-hover:scale-110" />
            <div className="absolute inset-0 bg-indigo-500 opacity-50 group-hover:opacity-80 transition-opacity duration-300"></div>
            <div className="absolute inset-0 flex flex-col justify-end items-center z-10 p-4">
              <p className="text-lg font-semibold font-atyp text-white">Timilehin Israel</p>
              <p className="text-gray-200 font-atyp">Print Design/Logistics</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
