import React, { useEffect, useState } from 'react';

const useCountUp = (end, duration) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const increment = end / (duration / 10);
    const handle = setInterval(() => {
      start += increment;
      if (start >= end) {
        clearInterval(handle);
        start = end;
      }
      setCount(Math.floor(start));
    }, 10);

    return () => clearInterval(handle);
  }, [end, duration]);

  return count;
};

const CompleteProject = () => {
  const projectsCompleted = useCountUp(958, 2000);
  const yearsExperience = useCountUp(13, 2000);
  const professionalDesigners = useCountUp(20, 2000);
  const digitalProducts = useCountUp(1859, 2000);

  return (
    <div className="py-10 px-0 text-white flex justify-center">
      <div className="w-5/7 bg-lime-300 rounded-3xl p-10">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-center md:space-x-4">
          <div className="flex items-center justify-center bg-blue-500 text-white rounded-lg shadow-lg p-8 w-full md:w-1/4 mb-4 md:mb-0 hover:bg-blue-600 transition duration-300 hover:scale-75">
            <div className="text-center">
              <h2 className="text-8xl font-bold">{projectsCompleted}+</h2>
              <p className="text-lg mt-2">Projects Completed</p>
              <p className="mt-2 text-sm">We have executed over 958 projects since 2015.</p>
            </div>
          </div>
          <div className="flex items-center justify-center bg-white text-blue-700 rounded-lg shadow-lg p-8 w-full md:w-1/4 mb-4 md:mb-0 hover:bg-gray-200 transition duration-300 hover:scale-75">
            <div className="text-center">
              <h2 className="text-8xl font-bold">{yearsExperience}+</h2>
              <p className="text-lg mt-2">Years Experience</p>
            </div>
          </div>
          <div className="flex items-center justify-center bg-white text-blue-700  rounded-lg shadow-lg p-8 w-full md:w-1/4 mb-4 md:mb-0 hover:bg-gray-200 transition duration-300 hover:scale-75">
            <div className="text-center">
              <h2 className="text-8xl font-bold">{professionalDesigners}</h2>
              <p className="text-lg mt-2">Professional Designers</p>
            </div>
          </div>
          <div className="flex items-center justify-center bg-white text-blue-700  rounded-lg shadow-lg p-8 w-full md:w-1/4 hover:bg-gray-200 transition duration-300 hover:scale-75">
            <div className="text-center">
              <h2 className="text-8xl font-bold">{digitalProducts}</h2>
              <p className="text-lg mt-2">Digital Products</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteProject;
