import React, { useState } from 'react';
import img1 from '../assets/images/1.jpg';
import img2 from '../assets/images/Finano.jpeg';
import img3 from '../assets/images/2.jpg';
import img4 from '../assets/images/1.jpg';
import img5 from '../assets/images/Finano.jpeg';
import img6 from '../assets/images/2.jpg';
// Import more images as needed


import './Project.css';

const categories = [
  'All',
  'Branding',
  'Apps',
  'Websites',
  'Books',
  'Social Media Management',
];

const projects = [
  { image: img1, category: 'Branding' },
  { image: img2, category: 'Apps' },
  { image: img3, category: 'Websites' },
  // Use the imported images or placeholders
  { image: img1, category: 'Books' },
  { image: img2, category: 'Social Media Management' },
  { image: img3, category: 'Branding' },
  // Add more projects as needed
];

const Project = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');

  const filteredProjects = selectedCategory === 'All'
    ? projects
    : projects.filter(project => project.category === selectedCategory);

  return (
    <div className="projects-main-container max-w-7xl mx-auto p-8">
      <section className="project-top text-center mb-10 font-atyp bg-slate-200 py-28 flex flex-col items-center">
        <h1 className="projects-title text-4xl font-bold mb-4 font-atyp">Project Highlights</h1>
        <p className="projects-para text-gray-600 font-atyp w-2/3">
          Get in Touch with Wells Ideas Whether you have a project in mind, need some branding expertise, or just want to chat about app and web development, we're here to help. Reach out to us through any of the following channels, and let's start a conversation.
        </p>
      </section>

      <div className="project-cat mb-8 flex justify-center space-x-4 font-atyp mt-32">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`px-4 py-2 ${selectedCategory === category ? 'bg-blue-500 text-white' : 'text-black'}`}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-20">
        {filteredProjects.map((project, index) => (
          <div
            key={index}
            className="relative overflow-hidden group rounded-lg border"
          >
            <div className="relative w-full h-0 pb-[100%]">
              <img
                src={project.image}
                alt={`Project ${index + 1}`}
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <button className="bg-white text-black px-4 py-2 rounded-full">
                See Case Study
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Project;
