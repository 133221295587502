import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Assuming you have a CSS file for the Footer styles
import logo from '../assets/images/ft-logo-gray.png'; // Adjust the path as necessary

const Footer = () => {
  return (
    <footer className="footer">


      <div className="footer-container">
        <div className="footer-logo">
          <Link to="/">
            <img src={logo} alt="Wells Logo" />
          </Link>
        </div>
        <div className="footer-links">
          <div className="footer-section">
            <h4 className="footer-title">About Us</h4>
            <ul>
              <li><Link to="/about">Our Story</Link></li>
              <li><Link to="/team">Team</Link></li>
              <li><Link to="/careers">Careers</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4 className="footer-title">Services</h4>
            <ul>
              <li><Link to="/branding">Branding</Link></li>
              <li><Link to="/development">Development</Link></li>
              <li><Link to="/design">Design</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4 className="footer-title">Resources</h4>
            <ul>
              <li><Link to="/blog">Blog</Link></li>
              <li><Link to="/case-studies">Case Studies</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
        </div>
        <div className="footer-social">
          <h4 className="footer-title">Follow Us</h4>
          <div className="social-icons">
            <a href="https://facebook.com/wellsideas" target="_blank" rel="noopener noreferrer">
              <i className="ri-facebook-line"></i>
            </a>
            <a href="https://twitter.com/wells_ideas" target="_blank" rel="noopener noreferrer">
              <i className="ri-twitter-line"></i>
            </a>
            <a href="https://instagram.com/wellsideas" target="_blank" rel="noopener noreferrer">
              <i className="ri-instagram-line"></i>
            </a>
            <a href="https://linkedin.com/wellsideas" target="_blank" rel="noopener noreferrer">
              <i className="ri-linkedin-line"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Wells Ideas. All rights reserved.</p>
        <a href="#top" className="back-to-top">
          <i className="ri-arrow-up-line"></i>
        </a>
      </div>
    </footer>
    
  );
};

export default Footer;
