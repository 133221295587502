import React, { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    name: "",
    message: "",
    newsletterEmail: "", // For newsletter subscription
  });
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://www.wellsideas.com/sendEmail.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          email: formData.email,
          phone: formData.phone,
          name: formData.name,
          message: formData.message,
        }),
      });

      const data = await response.text();
      setResponseMessage(data);
    } catch (error) {
      setResponseMessage("An error occurred. Please try again.");
    }
  };

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://www.wellsideas.com/subscribeNewsletter.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          email: formData.newsletterEmail,
        }),
      });

      const data = await response.text();
      setResponseMessage(data);
    } catch (error) {
      setResponseMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-8 font-custom">
      <section className="text-center mb-10 font-atyp bg-slate-200 py-28 flex flex-col items-center rounded-xl ">
        <h1 className="text-4xl font-bold mb-4 font-atyp">Contact Us</h1>
        <p className="text-gray-600 font-atyp w-2/3">
          Get in Touch with Wells Ideas...
        </p>
      </section>

      {/* Contact Form */}
      <section className="flex flex-col md:flex-row justify-between mb-10 mt-40 space-y-8 md:space-y-0 md:space-x-8 font-atyp">
        <div className="w-full md:w-2/5 font-atyp">
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="w-full p-4 mb-4 border border-gray-300 rounded-full font-atyp"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone"
            className="w-full p-4 mb-4 border border-gray-300 rounded-full"
            value={formData.phone}
            onChange={handleChange}
          />
          <input
            type="text"
            name="name"
            placeholder="Name"
            className="w-full p-4 mb-4 border border-gray-300 rounded-full"
            value={formData.name}
            onChange={handleChange}
          />
          <textarea
            name="message"
            placeholder="Message"
            className="w-full p-4 mb-4 border border-gray-300 rounded-3xl h-40 font-atyp"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          <button 
            className="w-full h-14 bg-blue-500 text-white py-3 rounded-full hover:bg-blue-600 font-atyp"
            onClick={handleContactSubmit}
          >
            Submit Inquiry
          </button>
          {responseMessage && <p>{responseMessage}</p>}
        </div>

        <div className="hidden md:block w-5 border-l border-blue-300"></div> {/* Light blue vertical line */}

        {/* Newsletter Subscription */}
        <div className="w-full md:w-2/5 bg-blue-100 p-12 rounded font-atyp rounded-3xl ">
          <h3 className="text-xl font-semibold mb-4 font-atyp">Our Newsletters</h3>
          <p className="text-gray-600 mb-4 font-atyp">
            Stay ahead of the curve with the Wells Ideas Newsletter...
          </p>
          <input
            type="email"
            name="newsletterEmail"
            placeholder="Email"
            className="w-full p-4 mb-4 border border-gray-300 rounded-full"
            value={formData.newsletterEmail}
            onChange={handleChange}
          />
          <button 
            className="w-full h-14 bg-gray-800 p-4 mb-4 text-white py-3 rounded-3xl hover:bg-gray-900"
            onClick={handleNewsletterSubmit}
          >
            Subscribe
          </button>
          {responseMessage && <p>{responseMessage}</p>}
        </div>
      </section>
    </div>
  );
};

export default Contact;
