import React, { useState } from 'react';

import './Testimonial.css'; // Import the CSS file


const Testimonial = () => {
  const testimonials = [
    {
      name: "Madam Ijeoma",
      position: "Pegasus Eyecare Clinic",
      text: "Since 2020, wells ideas team has been our IT expert. We have been working together with the team",
      imgSrc: "path_to_image_1",
      backgroundColor: "bg-white",
    },
    {
      name: "Casmire Okedinachi",
      position: "Casmire Real Estate",
      text: "Very different from conventional agency, this one is easier, easier to learn and easy to remember",
      imgSrc: "path_to_image_2",
      backgroundColor: "bg-blue-600 text-white",
    },
    {
      name: "Madam May Chioma, USA",
      position: "Maxwell & Bessie Inc",
      text: "Creative approach is topnotch, professionally, Wells Ideas executed with great success",
      imgSrc: "path_to_image_3",
      backgroundColor: "bg-white",
    },
    {
      name: "Mr Daniel",
      position: "Athproletic Inc, CANADA",
      text: "The services provided are top-notch, very effective and efficient.",
      imgSrc: "path_to_image_4",
      backgroundColor: "bg-white",
    },
    {
      name: "Mr Stanley",
      position: "Dp Integrated Global Links",
      text: "An excellent experience from start to finish, highly recommended.",
      imgSrc: "path_to_image_5",
      backgroundColor: "bg-white",
    },
    {
      name: "Beauty Sunday",
      position: "R&D, Electronics & Home Appliances",
      text: "An excellent experience from start to finish, highly recommended.",
      imgSrc: "path_to_image_6",
      backgroundColor: "bg-white",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const getVisibleTestimonials = () => {
    const endIndex = currentIndex + 3;
    if (endIndex > testimonials.length) {
      return testimonials.slice(currentIndex).concat(testimonials.slice(0, endIndex - testimonials.length));
    }
    return testimonials.slice(currentIndex, endIndex);
  };

  return (
    <div className="flex flex-col items-center px-4 py-16 bg-gray-50 md:px-20 lg:px-40">
      <h2 className="text-2xl font-bold mb-6 text-center md:text-3xl lg:text-4xl">
        What Our <span className="text-blue-600">Satisfied</span> Clients Say
      </h2>
      <div className="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-6 overflow-hidden">
        {getVisibleTestimonials().map((testimonial, index) => (
          <div
            key={index}
            className={`p-6 rounded-lg shadow-lg ${testimonial.backgroundColor} w-full md:w-80 lg:w-96`}
          >
            <img src={testimonial.imgSrc} alt={testimonial.name} className="rounded-full w-16 h-16 mb-4 mx-auto" />
            <h3 className="text-lg font-semibold text-center">{testimonial.name}</h3>
            <p className="text-sm mb-2 text-center">{testimonial.position}</p>
            <p className="text-sm mb-4 text-center">{testimonial.text}</p>
            <a href="#" className="text-blue-600 flex items-center justify-center">
              Learn more <span className="ml-2">→</span>
            </a>
          </div>
        ))}
      </div>
      <div className="flex space-x-2 mt-6">
        <button
          className="bg-white shadow-md p-2 rounded-full"
          onClick={prevTestimonial}
        >
          <span className="sr-only">Previous</span>
          <svg
            className="w-6 h-6 text-blue-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
          </svg>
        </button>
        <button
          className="bg-blue-600 shadow-md p-2 rounded-full"
          onClick={nextTestimonial}
        >
          <span className="sr-only">Next</span>
          <svg
            className="w-6 h-6 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Testimonial;
