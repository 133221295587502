import React from 'react';
import { useNavigate } from 'react-router-dom';

const works = [
  {
    title: 'A Real Estate Titan',
    description: 'Engaged us to redesign and enhance their core brand consistency .',
    imageSrc: require('../assets/images/1.jpg'), // updated to use require
  },
  {
    title: 'Finano Mobile App',
    description: 'Having us on board to create a Fintech App redefined.',
    imageSrc: require('../assets/images/Finano.jpeg'), // replace with actual image path
  },
  {
    title: 'Ideation to Execution',
    description: 'We were tasked to create a brand strategy/web development.',
    imageSrc: require('../assets/images/2.jpg'), // replace with actual image path
  },
];

const Work = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900">
            Projects We <br />
            <span className="text-blue-600">have Completed</span>
          </h2>
          <div className="space-x-4">
            <button
              className="bg-white text-gray-900 py-2 px-4 rounded-lg shadow-md hover:bg-gray-100 transition"
              onClick={() => navigate('/contact')}
            >
              Start New Project
            </button>
            <button
              className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 transition"
              onClick={() => navigate('/projects')}
            >
              View All Work
            </button>
          </div>
        </div>
        <div className="flex space-x-4 overflow-x-auto">
          {works.map((work, index) => (
            <div key={index} className="flex-none w-80 transform transition-transform duration-300 hover:scale-105">
              <div className="relative">
                <img
                  src={work.imageSrc}
                  alt={work.title}
                  className="w-full h-48 rounded-t-xl object-cover"
                />
                <div className="absolute top-4 right-4 bg-white rounded-full p-2 shadow-md">
                  <svg
                    className="w-6 h-6 text-blue-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="bg-white p-4 rounded-b-xl shadow-lg">
                <h3 className="text-xl font-semibold mb-2">{work.title}</h3>
                <p className="text-gray-600">{work.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Work;
