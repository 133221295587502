import React from 'react';

import './Development.css';


const Development = () => {
  return (
    <div className="max-w-7xl mx-auto p-8">
      <section className="dev-container text-center mb-10 font-atyp bg-slate-200 py-28 flex flex-col items-center rounded-xl ">
  <h1 className="dev-title text-4xl font-bold mb-4 font-atyp text-blue-600">Bring your ideas to life.</h1>
  <p className="text-gray-600 font-atyp w-2/3">
  Got a vision, a branding challenge, or curious about app and web development? Let’s make it happen together. Whether you’re ready to dive into a new project or just want to explore possibilities, we’re all ears. Reach out to us, and let’s turn your ideas into reality.
</p>
</section>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="p-6 bg-gray-100 rounded-lg  font-atyp">
          <h3 className="text-2xl font-bold mb-4">Mobile App Development</h3>
          <p className="mb-4">
            We specialize in creating high-quality mobile applications for both iOS and Android platforms.
            Our team ensures a seamless user experience, engaging interfaces, and robust functionality.
          </p>
          <button className="bg-blue-500 text-white px-4 py-2 rounded-full">
            Request a Quote
          </button>
        </div>

        <div className="p-6 bg-gray-100 rounded-lg  font-atyp">
          <h3 className="text-2xl font-bold mb-4">Web App Development</h3>
          <p className="mb-4">
            Our web app development services provide scalable and secure solutions tailored to your business needs.
            Whether it's a simple website or a complex web application, we've got you covered.
          </p>
          <button className="bg-blue-500 text-white px-4 py-2 rounded-full">
            Request a Quote
          </button>
        </div>
      </div>
    </div>
  );
};

export default Development;
