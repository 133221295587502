import React from 'react';
import contactImage from '../assets/images/team.png';
import 'remixicon/fonts/remixicon.css'; // Import Remixicon CSS
import './Letschat.css';

const Letschat = () => {
  // Generate 10 user icons
  const peopleIcons = Array.from({ length: 10 }, (_, index) => (
    <div className="person" key={index}>
      <i className="ri-user-line"></i>
    </div>
  ));

  return (
    <div className="letschat-container">
      <div className="letschat-content">
        <div className="letschat-text">
          <h1 className="awesome">Have an Awesome Project?</h1>
          <p className="new">We are available for new project, <span>Contact Us!</span></p>
          <div className="letschat-people">
            <div className="people-icons">{peopleIcons}</div>
            <span className='outstand'>10+ Outstanding People</span>
          </div>
        </div>
        <div className="letschat-image-container">
          <img src={contactImage} alt="Stay Productive" className="letschat-image" />
        </div>
      </div>
    </div>
  );
};

export default Letschat;
