import React from 'react';
import Header from './Header';
import Slider from './Slider';
import Footer from './Footer';
import WhoWeAre from './WhoWeAre';
import Works from './Works';
import Services from './Services';
import Partners from './Partners';
import Testimonial from './Testimonial';
import CompleteProject from './CompleteProject';
import Letschat from './Letschat';



const Home = () => {
  return (
    <div style={{
      position: 'relative',
      minHeight: '100vh',
      fontFamily: 'Atyp, sans-serif',
      overflow: 'hidden',
    }}>
      <div style={{ 
        
  
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
      }}>
      </div>
      <Slider />
      <WhoWeAre />
      <CompleteProject />
      <Services />
      <Works />
      <Letschat />
      <Testimonial />
      <Partners />
    </div>
  );
};

export default Home;
